import React, { useState, useEffect } from "react";
import axios from "axios";
import { arrowRightIcon, userIcon } from "./assets/assets";

const DAWI_API_URL = process.env.REACT_APP_DAWI_API_URL || 'https://dawi.mykuwaitnet.net/en/api/';

const Sidebar = ({ activeSection, remoteUsers, onClose, sidebarOpen, mainUser }: any) => {
  const [usersData, setUsersData] = useState<any[]>([]);

  useEffect(() => {
    if (sidebarOpen) {
      remoteUsers.forEach((user: any) => {
        getUser(user.uid);
      });
    }
  }, [remoteUsers, sidebarOpen]);

  const getUser = (userId: any) => {
    axios.get(`${DAWI_API_URL}v2/user-data/${userId}/`)
      .then((response) => {
        const userData = response.data;
        if (!usersData.some((user) => user.id === userId)) {
          setUsersData((prevUsersData) => [...prevUsersData, userData]);
        }
      })
      .catch((error) => {
        console.log('Error fetching user data:', error);
      });
  };

  const toggleSidebar = () => {
    onClose();
  };

  const fullUserData = usersData.filter((user, index, self) =>
    index === self.findIndex((u) => u.id === user.id)
  );

  return (
    <div className="sidebar">
      <button className="btn close-button" onClick={toggleSidebar}>
        <img src={arrowRightIcon} className="icons-style" alt="arrowright" />
      </button>
      {activeSection === "members" && sidebarOpen && (
        <div className="members-list">
          <h2>Members</h2>
          <ul className="user-cards">
            <li className="user-card-main">
              <div className="user-avatar-main">
                <img src={userIcon} alt="User Avatar" />
              </div>
              <div className="user-info-main">
                <h3>{mainUser ? mainUser.username || `${mainUser.first_name} ${mainUser.last_name}` : 'Main'} (YOU)</h3>
                <p>{mainUser ? mainUser.email : "Mail ID"}</p>
              </div>
            </li>
            {fullUserData.map((userData) => (
              <li key={userData.id} className="user-card">
                <div className="user-avatar">
                  <img src={userIcon} alt="User Avatar" />
                </div>
                <div className="user-info">
                  <h3>{userData ? userData.username || `${userData.first_name} ${userData.last_name}` : 'Remote'}</h3>
                  <p>{userData ? userData.email : "Remote Email"}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {activeSection === "chat" && (
        <div className="chat-box">
          <h2>Chat</h2>
          <div className="messages"></div>
          <input type="text" placeholder="Type a message..." />
          <button>Send</button>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
