import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AgoraRTC, {
  LocalUser,
  RemoteUser,
  useIsConnected,
  useJoin,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  usePublish,
  useRemoteUsers,
} from "agora-rtc-react";
import "./AgoraVideoMeet.css";
import { userIcon, micOnIcon, micOffIcon, videoOnIcon, videoOffIcon, endCallIcon, screenShareIcon, stopShareIcon, maximizeIcon, yesIcon, noIcon, loadingIcon, membersIcon } from './assets/assets';
import { LocalVideoTrack, useLocalScreenTrack } from "agora-rtc-react";
import { useLocation } from 'react-router-dom';
import VirtualBackground from './VirtualBackground';
import Sidebar from './SideBar';

interface User {
  [key: string]: any;
}

const DAWI_API_URL = process.env.REACT_APP_DAWI_API_URL || 'https://dawi.mykuwaitnet.net/en/api/';
const DAWI_HOME_URL = process.env.REACT_APP_DAWI_HOME_URL || 'https://dawi.mykuwaitnet.net';

const App: React.FunctionComponent = () => {
  const [calling, setCalling] = useState(true);
  const [showEndCallModal, setShowEndCallModal] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const isConnected = useIsConnected();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const data = query.get("data");
  const user: User = data ? JSON.parse(decodeURIComponent(data)) : {};
  const [isSidebarOpen, setIsSideBarOpen] = useState(false);
  const [activeSidebarSection, setActiveSidebarSection] = useState('');
  const [isRemoteScreenFit, setIsRemoteScreenFit] = useState(true);

  const config = {
    appId: user.appId ? user.appId : "",
    channel: user.channel ? user.channel : "",
    token: user.token ? user.token : null,
    uid: user.uid ? parseInt(user.uid) : 0
  }

  useJoin({ appid: config.appId, channel: config.channel, token: config.token, uid: config.uid }, calling);
  const [micOn, setMic] = useState(false);
  const [cameraOn, setCamera] = useState(false);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [mainScreen, setMainScreen] = useState('local');
  const remoteUsers = useRemoteUsers();
  const [remoteUserBig, setRemoteUserBig] = useState(remoteUsers[1]);
  const { screenTrack, error } = useLocalScreenTrack(isScreenSharing, {}, "disable");
  const [mainUser, setMainUser] = useState<any>(null);
  const [remoteBigUserData, setRemoteBigUserData] = useState<any>(null);
  const [isShowMiniVideo, setIsShowMiniVideo] = useState(false);

  const fetchUserData = async (userId: any) => {
    try {
      const response = await axios.get(`${DAWI_API_URL}v2/user-data/${userId}/`);
      return response.data;
    } catch (error) {
      console.log('Error fetching user data:', error);
      return null;
    }
  };

  useEffect(() => {
    if (screenTrack) {
      screenTrack.on("track-ended", () => {
        setIsScreenSharing(false);
        setMainScreen('local');
        screenTrack.close();
      });
    }


    if (remoteUsers.length > 0) {
      handleRemoteUserSwap(remoteUsers[0])
      fetchRemoteUserData(remoteUsers[0].uid)
    } else {
      handleLocalUserSwap()
    }

    fetchMainUserData();

    AgoraRTC.createMicrophoneAndCameraTracks()

    if (error) {
      handleScreenShareEnd();
    }

    let timer: NodeJS.Timeout;
    if (calling) {
      timer = setInterval(() => {
        setElapsedTime(prevTime => prevTime + 1);
      }, 1000);
    } else {
      // clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [screenTrack, error, calling, remoteUsers]);

  const fetchMainUserData = async () => {
    const userData = await fetchUserData(config.uid);
    if (userData) {
      setMainUser(userData);
    }
  };

  const fetchRemoteUserData = async (userId: any) => {
    const userData = await fetchUserData(userId);
    if (userData) {
      setRemoteBigUserData(userData);
    }
  };

  const handleScreenShareEnd = () => {
    if (isScreenSharing) {
      setIsScreenSharing(!isScreenSharing)
      setMainScreen('local')
      screenTrack?.close()
    } else {
      setIsScreenSharing(!isScreenSharing)
      setMainScreen('sharing')
    }
  }

  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack } = useLocalCameraTrack(cameraOn, {encoderConfig: {bitrateMax: 2000, bitrateMin: 2000, height: 800, width: 2000, frameRate: 2000}});
  usePublish([localMicrophoneTrack, localCameraTrack, screenTrack]);

  const handleRemoteUserSwap = async (user: any) => {
    setMainScreen('remote');
    setRemoteUserBig(user);
  }

  const handleLocalUserSwap = () => {
    setMainScreen('local');
    setRemoteUserBig(remoteUsers[1]);
  }

  const handleSceenShareSwap = () => {
    setMainScreen('sharing');
    setRemoteUserBig(remoteUsers[1]);
  }

  const handleEndCall = () => {
    setCalling(false);
    setShowEndCallModal(false);
    window.location.href = `${DAWI_HOME_URL}${user.meeting_index_url}`;
  };

  const handleCancelEndCall = () => {
    setShowEndCallModal(false);
  };

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  if (!isConnected) {
    return (
      <div className="loader-container">
        <img src={loadingIcon} alt="Loading..." className="loader-icon-style" />
      </div>
    );
  }
  
  const handleSideBarOpen = (option: any) => {
    if (option === 'chat') {
      setActiveSidebarSection('chat');
    } else {
      setActiveSidebarSection('members');
    }
    setIsSideBarOpen(!isSidebarOpen);
  }

  return (
    <>
      <div className="main-class">
        <div className="user-list">
          <div className="user">
            {isScreenSharing && mainScreen === 'sharing' &&
              <LocalVideoTrack play track={screenTrack} />
            }
            {
              <>
                {mainScreen === 'local' &&
                  <LocalUser
                    audioTrack={localMicrophoneTrack}
                    cameraOn={cameraOn}
                    micOn={micOn}
                    videoTrack={localCameraTrack}
                    cover={userIcon}
                    className="user-video-box"
                  >
                    <samp className="user-name">{mainUser ? `${mainUser.first_name} ${mainUser.last_name}` : 'Local User'}</samp>
                  </LocalUser>
                }
                {mainScreen === 'remote' &&
                  <RemoteUser cover={userIcon} user={remoteUserBig} style={{ width: isRemoteScreenFit ? '51vh' : '100%' }}>
                    <button className={`btn`} onClick={() => setIsRemoteScreenFit(!isRemoteScreenFit)}>
                        <img src={maximizeIcon} className='icons-style' alt='maximize' />
                    </button>
                    <samp className="user-name">{remoteBigUserData ? `${remoteBigUserData.first_name} ${remoteBigUserData.last_name}` : `${remoteUserBig.uid} Remote User`}</samp>
                  </RemoteUser>
                }
              </>
            }
              <div className="remote-user" style={{ zIndex: !isShowMiniVideo ? '-1' : '100' }}>
                <>
                  {mainScreen === 'remote' &&
                    <LocalUser
                      audioTrack={localMicrophoneTrack}
                      cameraOn={cameraOn}
                      micOn={micOn}
                      videoTrack={localCameraTrack}
                      cover={userIcon}
                      className="local-user-video"
                    >
                      <button className={`btn`} onClick={() => handleLocalUserSwap()}>
                        <img src={maximizeIcon} className='icons-style' alt='maximize' />
                      </button>
                      <samp className="user-name">{mainUser ? `${mainUser.first_name} ${mainUser.last_name}` : 'Local User'}</samp>
                    </LocalUser>
                  }
                  {mainScreen === 'sharing' &&
                    <LocalUser
                      audioTrack={localMicrophoneTrack}
                      cameraOn={cameraOn}
                      micOn={micOn}
                      videoTrack={localCameraTrack}
                      cover={userIcon}
                      className="local-user-video"
                    >
                      <button className={`btn`} onClick={() => handleLocalUserSwap()}>
                        <img src={maximizeIcon} className='icons-style' alt='maximize' />
                      </button>
                      <samp className="user-name">{mainUser ? mainUser.username : 'Local User'}</samp>
                    </LocalUser>
                  }
                  {isScreenSharing && mainScreen === 'local' &&
                    <LocalVideoTrack play track={screenTrack} className='screenshare-user-video'>
                      <button className={`btn-screenshare`} onClick={() => handleSceenShareSwap()}>
                        <img src={maximizeIcon} className='icons-style' alt='maximize' />
                      </button>
                    </LocalVideoTrack>
                  }
                  {isScreenSharing && mainScreen === 'remote' &&
                    <LocalVideoTrack play track={screenTrack} className='screenshare-user-video'>
                      <button className={`btn-screenshare`} onClick={() => handleSceenShareSwap()}>
                        <img src={maximizeIcon} className='icons-style' alt='maximize' />
                      </button>
                    </LocalVideoTrack>
                  }
                </>
                {remoteUsers.map((user) => (
                  <>
                    {!(remoteUserBig === user) &&
                      <RemoteUser cover={userIcon} className='remote-user-video' user={user}>
                        <button className={`btn`} onClick={() => handleRemoteUserSwap(user)}>
                          <img src={maximizeIcon} className='icons-style' alt='maximize' />
                        </button>
                        <samp className="user-name">{remoteBigUserData ? `${remoteBigUserData.first_name} ${remoteBigUserData.last_name}` : `${user.uid} Remote User`}</samp>
                      </RemoteUser>
                    }
                  </>
                ))}
              </div>
          </div>
        </div>
        {showEndCallModal && (
          <div className="modal">
            <div className="modal-content">
              <p>Are you sure you want to end the call?</p>
              <div className='modal-btns'>
                <button className="modal-btn-yes" onClick={() => handleEndCall()}>
                  <img src={yesIcon} className='icons-style' alt='maximize' />
                </button>
                <button className="modal-btn-no" onClick={() => handleCancelEndCall()}>
                  <img src={noIcon} className='icons-style' alt='maximize' />
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="call-timer">
          {formatTime(elapsedTime)} | {user.channel ? user.channel : "Local"}
        </div>
        {isConnected && (
          <div className="control-main">
            <div className="controls">
              <button className={`btn ${micOn ? "" : "btn-off"}`} onClick={() => setMic(a => !a)}>
                <img src={micOn ? micOnIcon : micOffIcon} className='icons-style' alt='mike' />
              </button>
              <button className={`btn ${cameraOn ? "" : "btn-off"}`} onClick={() => setCamera(a => !a)}>
                <img src={cameraOn ? videoOnIcon : videoOffIcon} className='icons-style' alt='camera' />
              </button>
              <button className={`btn ${isScreenSharing ? "btn-off-screenshare" : ""}`} onClick={handleScreenShareEnd}>
                <img src={isScreenSharing ? stopShareIcon : screenShareIcon} className='icons-style' alt='screenshare' />
              </button>
              <VirtualBackground localCameraTrack={localCameraTrack} setMiniVideo={setIsShowMiniVideo} miniVideo={isShowMiniVideo}/>
              <button
                className={`btn btn-phone`}
                onClick={() => setShowEndCallModal(true)}
              >
                {calling ? <img src={endCallIcon} className='icons-style' alt='endcall' /> : ""}
              </button>
            </div>
          </div>
        )}
        <div className="right-buttons">
          <div>
            <div className='members-count-container'>
              <p className='members-count'>{remoteUsers.length + 1}</p>
            </div>
            <button className="btn btn-members" onClick={() => handleSideBarOpen('members')}>
              <img src={membersIcon} className='icons-style' alt='members' />
            </button>
            {/* <button className="btn" onClick={() => handleSideBarOpen('chat')}>
              <img src={chatIcon} className='icons-style' alt='chat' />
            </button> */}
          </div>
        </div>
        {isSidebarOpen && (
          <Sidebar activeSection={activeSidebarSection} remoteUsers={remoteUsers} onClose={() => setIsSideBarOpen(false)} sidebarOpen={isSidebarOpen} mainUser={mainUser} />
        )}
      </div>
    </>
  );
};

export default App;
