import VirtualBackgroundExtension, {
    IVirtualBackgroundProcessor
} from 'agora-extension-virtual-background';
import AgoraRTC from 'agora-rtc-react';
import { useEffect, useState, useRef } from 'react';
import { backgroundIcon, blurOffIcon, blurOnIcon, dotsIcon } from './assets/assets';

interface VirtualBackgroundProps {
    localCameraTrack: any;
    setMiniVideo: (miniVideo: boolean) => void;
    miniVideo: boolean;
}

const VirtualBackground: React.FC<VirtualBackgroundProps> = ({ localCameraTrack, setMiniVideo, miniVideo }) => {
    const [extensionActive, setExtensionActive] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const ext = useRef(new VirtualBackgroundExtension());
    const processor = useRef<IVirtualBackgroundProcessor>();

    useEffect(() => {
        const initExtension = async () => {
            AgoraRTC.registerExtensions([ext.current]);
            processor.current = ext.current.createProcessor();
            await processor.current.init('<Path to WASM module>');
        };
        initExtension();
    }, []);

    const enableBackground = async () => {
        if (processor.current && localCameraTrack) {
            localCameraTrack.pipe(processor.current).pipe(localCameraTrack.processorDestination);
            processor.current.setOptions({ type: 'blur', blurDegree: 1, color: '#ff00ff' });
            await processor.current.enable();
            setExtensionActive(true);
        }
    };

    const disableBackground = async () => {
        if (processor.current && localCameraTrack) {
            localCameraTrack.unpipe();
            await processor.current.disable();
            setExtensionActive(false);
        }
    };

    const toggleBlur = async () => {
        if (extensionActive) {
            await disableBackground();
        } else {
            await enableBackground();
        }
        setDropdownVisible(false);
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    return (
        <div style={{ position: 'relative' }}>
            <button className='btn' onClick={toggleDropdown}>
                <img src={dotsIcon} className='icons-style' alt='Menu' />
            </button>
            {dropdownVisible && (
                <div style={dropdown}>
                    <div style={dropdownItem} onClick={toggleBlur}>
                        <div style={itemContent}>
                            <p>Apply Background</p>
                            <div className='virtual-back-btn'>
                                <img src={backgroundIcon} className='icons-style' alt='Back' />
                            </div>
                        </div>
                        {extensionActive ? (
                            <div style={itemContent}>
                                <p>Disable Blur</p>
                                <div className='virtual-back-btn'>
                                    <img src={blurOffIcon} className='icons-style' alt='Disable Blur' />
                                </div>
                            </div>
                        ) : (
                            <div style={itemContent}>
                                <p>Enable Blur</p>
                                <div className='virtual-back-btn'>
                                    <img src={blurOnIcon} className='icons-style' alt='Enable Blur' />
                                </div>
                            </div>
                        )}
                        <div style={itemContent} onClick={() => setMiniVideo(!miniVideo)}>
                            <p>{miniVideo ? "Hide Mini Video" : "Show Mini Video"}</p>
                            <div className='virtual-back-btn'>
                                <img src={backgroundIcon} className='icons-style' alt='Show mini' />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const dropdown = {
    position: 'absolute' as 'absolute',
    width: 'max-content',
    top: '-210%',
    left: '40px',
    backgroundColor: 'white',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    zIndex: 100,
};

const dropdownItem = {
    padding: '0px 10px',
    cursor: 'pointer',
    borderBottom: '1px solid #e0e0e0',
    textAlign: 'center' as 'center',
    backgroundColor: 'white',
};

const itemContent = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'black',
};

export default VirtualBackground;
